@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  font-family: 'Montserrat', sans-serif;
}

body {
  @apply tw-font-sans tw-text-black-100 tw-font-medium tw-bg-gray-10;
  a {
    @apply tw-text-blue-links tw-cursor-pointer tw-px-1;
    img {
      @apply tw-ml-1;
    }
  }
}

.bg-home-desktop {
  background: url('../src/assets/images/Homepage-Desktop.jpg');
  background-repeat: repeat;
  background-position: top center;
  background-attachment: fixed;
  background-size: 100%;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.bg-home-mobile {
  background: url('../src/assets/images/Homepage-Mobile.jpg');
  background-repeat: repeat;
  background-position: top center;
  background-size: 100%;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.scoot-container {
  @apply tw-max-w-286.5;
}

/*Heading*/
h1 {
  @apply tw-font-bold;
  .tw-h1-lg {
    @apply tw-text-7;
  }
  .tw-h1-sm {
    @apply tw-text-5.75;
  }
}
h2 {
  @apply tw-font-bold;
  .tw-h2-lg {
    @apply tw-text-5.75;
  }
  .tw-h2-sm {
    @apply tw-text-4.75;
  }
}
h3 {
  @apply tw-font-bold;
  .tw-h3-lg {
    @apply tw-text-4.75;
  }
  .tw-h3-sm {
    @apply tw-text-4;
  }
}
/*Heading*/

/*Body*/
.tw-b1-lg-bold {
  @apply tw-text-4 tw-font-bold;
}
.tw-b1-lg-medium {
  @apply tw-text-4 tw-font-medium;
}
.tw-b1-sm-bold {
  @apply tw-text-3.25 tw-font-bold;
}
.tw-b1-sm-medium {
  @apply tw-text-3.25 tw-font-medium;
}
/*Body*/

/*Caption*/
.tw-c1-lg-bold {
  @apply tw-text-2.75 tw-font-bold;
}
.tw-c1-lg-medium {
  @apply tw-text-2.75 tw-font-medium;
}
.tw-c1-sm-bold {
  @apply tw-text-2.25 tw-font-bold;
}
.tw-c1-sm-medium {
  @apply tw-text-2.25 tw-font-medium;
}
/*Caption*/

/*Form*/
.tw-radio {
  @apply tw-w-5 tw-h-5;
}

.tw-checkbox {
  @apply tw-min-w-4.5 tw-min-h-4.5;
}

.tw-text-input {
  background: transparent;
  left: 0;
  width: 100%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border: 0rem;
  border-bottom: 0.0625rem solid #1f201e;
  z-index: 20;
  overflow: hidden;
  @apply tw-outline-none tw-pt-0.75;
  &.ng-dirty.ng-invalid {
    @apply tw-border-red-100;
    &:hover {
      @apply tw-border-[3px] tw-border-red-100;
    }
  }
  &.ng-touched.ng-invalid {
    @apply tw-border-red-100;
    &:hover {
      @apply tw-border-[3px] tw-border-red-100;
    }
  }
  &:focus {
    margin-bottom: -0.25rem;
    border-bottom: 0.3125rem solid #1f201e;
  }
  &.ng-dirty.ng-invalid:focus {
    margin-bottom: -0.25rem;
    border-bottom: 0.3125rem solid #ca1825;
  }
  &.ng-touched.ng-invalid:focus {
    margin-bottom: -0.25rem;
    border-bottom: 0.3125rem solid #ca1825;
  }
  &:hover {
    border-bottom: 3px solid black;
    @apply tw-bg-yellow-40;
  }
  &:focus ~ span {
    @apply tw-text-black-100;
  }
}
/**/

/*Button*/
.tw-btn {
  @apply tw-w-full tw-h-10 tw-rounded-5 tw-text-base tw-font-semibold;
}

.tw-btn-primary {
  @apply tw-bg-black-100 tw-text-white;
  &:hover {
    @apply tw-bg-black-80 tw-text-yellow-100;
    img{
       filter:brightness(0) saturate(100%) invert(85%) sepia(81%) saturate(7499%) hue-rotate(358deg) brightness(106%) contrast(101%);
    }
  }
  &:disabled {
    @apply tw-bg-black-40 tw-pointer-events-none;
  }
}

.tw-btn-secondary {
  @apply tw-border tw-border-black-100 tw-bg-black-10 tw-text-black-100 tw-shadow-shadows;
  &:hover {
    @apply tw-bg-yellow-20 tw-text-black-100;
  }
  &:disabled {
    @apply tw-bg-black-40 tw-border-black-40 tw-bg-black-10 tw-text-black-40 tw-pointer-events-none;
  }
}

.tw-btn-tertiary {
  @apply tw-border-2 tw-border-black-100 tw-text-black-100 tw-shadow-shadows;
}
/*Button*/

/*Others*/
.add-ons-header {
  @apply tw-hidden;
}
.header-banner {
  display: none !important;
}
.subheader.gap-more-addons {
  @apply tw-hidden;
}
.add-ons-dashboard {
  padding-left: 0 !important;
  margin-bottom: 30px;
}
span[aria-label='review summary title'] {
  @apply tw-hidden;
}
[aria-label='Subtitle for Add-ons Baggage card']{
  @apply tw-hidden;   
}
.panel.ng-tns-c180-1{
  @apply tw-hidden;   
}
.panel.ng-tns-c180-2{
margin-top: -30px;
}
.panel.ng-tns-c114-1{
  @apply tw-hidden;   
}
.panel.ng-tns-c114-2{
  margin-top: -30px;  
}
div.card-main-container {
  height: auto !important;
}

//loading
.rotating-image {
  animation: rotate 3s linear infinite;
  width: 15vw; 
}
@media (max-width: 768px) { 
  .rotating-image {
    width: 40vw; 
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@layer components {
  .scoot-card {
    @apply tw-bg-white tw-rounded-5 tw-p-[1.875rem];
  }

  .scoot-overlay {
    @apply tw-fixed tw-top-0 tw-z-[9000] tw-h-full tw-w-full tw-justify-center tw-flex tw-bg-black-100 tw-bg-opacity-80 tw-items-baseline tw-overflow-y-scroll tw-overflow-x-hidden;
  }

  .scoot-button {
    @apply tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-5 tw-font-semibold tw-leading-4 focus:tw-outline-none;
  }

  .scoot-primary-button {
    @apply scoot-button tw-bg-black-100 tw-px-5 tw-py-3 tw-rounded-full tw-border-2 tw-border-black-100 tw-text-white  hover:tw-border-black-80 hover:tw-bg-black-80 hover:tw-text-yellow-100;

    &:disabled {
      @apply tw-bg-black-40 tw-cursor-default tw-border-2 tw-border-black-40 hover:tw-bg-black-40 hover:tw-text-white;
    }
  }

  .scoot-primary-outline-button {
    @apply scoot-button tw-bg-transparent tw-rounded-full tw-border-2 tw-border-black-100 tw-px-5 tw-py-3 tw-text-black-100 hover:tw-bg-black-100 hover:tw-text-white;
  }
}

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

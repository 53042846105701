@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: url("/assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  src: url("/assets/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: url("/assets/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: url("/assets/fonts/Montserrat-Bold.ttf");
}